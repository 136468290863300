<script>
  import { DIRECTION_AUTO } from '@/router'
  import linkMixin from '@/mixins/linkMixin'

  /**
   * An unstyled link that points to a `vue-router` route or a URL, depending on the prop that is used (`to` or `href`).
   */
  export default {
    mixins: [linkMixin],
    data() {
      return {
        targetAriaDescription: 'Opens in a new tab or window',
      }
    },
    computed: {
      resolvedRoute() {
        if (!this.to || !this.$router) {
          return undefined
        }
        return this.$router.resolve(this.to)
      },
      finalHref() {
        if (this.resolvedRoute) {
          return this.resolvedRoute.href
        }
        return this.href
      },
    },
    methods: {
      click($event) {
        // If there is a click handler on the component, prevent the default link functionality
        if (this.$listeners.click) {
          $event.preventDefault()
          return
        }
        // If this is a router link, prevent the default link functionality...
        if (this.to) {
          // ...unless a control key is pressed
          if ($event.metaKey || $event.altKey || $event.ctrlKey || $event.shiftKey) {
            return
          }
          // ...unless the click wasn’t made with the left button
          if ($event.button !== undefined && $event.button !== 0) {
            return
          }
          // ...unless the link should open in a new tab/window
          if (this.target === '_blank') {
            return
          }
          $event.preventDefault()
          this.$navigate(this.to, this.direction ?? DIRECTION_AUTO)
        }
      },
    },
  }
</script>

<template>
  <a
    :href="finalHref"
    :target="target"
    :aria-description="target === '_blank' ? targetAriaDescription : undefined"
    class="cursor-pointer"
    v-on="$listeners"
    @keydown.enter="$emit('click')"
    @click="click"
  >
    <!-- @slot The link’s label. Should contain text. -->
    <slot />
  </a>
</template>
